import React, { useState, useEffect, useCallback } from 'react';
import style from './Select.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ModalWrapper from '../../common/modalWrapper/ModalWrapper';


const Select = (props) => {
  const { name, label, optionsList, multiple, checkboxes, callback, main, searchable, extraActions, autoWidth, mustResetFilters, canHighlight, optionsAlwaysVisible } = props;
  const [options, setOptions] = useState(undefined);
  const [backup, setBackup] = useState([]);
  const [selection, setSelection] = useState([]);
  const [displayOptions, setDisplayOptions] = useState(optionsAlwaysVisible ? true : false);
  const [tempSelection, setTempSelection] = useState(undefined);
  const [searchInput, setSearchInput] = useState('');
  const [coincidences, setCoincidences] = useState([]);
  const quickSelection = props.quickSelection || !multiple;
  const position = props.position ? props.position : 'left';

  useEffect(() => {
    let newOptions = optionsList.map((option, index) => { return { 
      ...option,
      ...{
        selected: option.selected || false,
        disabled: option.disabled || false,
        index: index,
        value: ['', undefined].includes(option.value) ? option.name : option.value
      } 
    } });
    setOptions(newOptions);
    setSelection(newOptions.filter(option => option.selected));
  }, [optionsList])

  const updateSelectionMobile = (selection) => {
    let newSelection = []
    for (let i = 0; i < selection.length; ++i) {
      newSelection.push({ name: selection[i].value, selected: true, value: selection[i].value });
    }
    if (callback) {
      callback(name, newSelection);
    }
  }

  const setNewSelectionDesktop = useCallback((uiInteraction = true) => {
    let newSelection = [];

    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        newSelection.push(options[i]);
      }
    }
    setSelection([...newSelection]);
    setTempSelection(undefined);
    setSearchInput('');
    if (callback) {
      callback(name, newSelection);
    }
    setBackup([]);
    if (optionsAlwaysVisible) return;
    if (uiInteraction) setDisplayOptions(!displayOptions);
  }, [callback, displayOptions, name, options, optionsAlwaysVisible])

  const selectAll = () => {
    if (backup.length > 0) {
      setOptions(backup);
      setBackup([]);
      return;
    }
    let temp = options.map(option => { return { ...option } })
    setBackup(temp);
    for (let i = 0; i < options.length; i++) {
      options[i].selected = true;
    }
    setOptions([...options]);
  }

  const clear = useCallback((update = true, uiInteraction = true) => {
    for (let i = 0; i < options.length; i++) {
      options[i].selected = false;
    }
    if (update) {
      setOptions([...options]);
      if (quickSelection) setNewSelectionDesktop(uiInteraction);
    }
    setBackup([]);
  }, [options, quickSelection, setNewSelectionDesktop])

  const updateSelection = (e, option) => {
    if (!multiple) {
      clear(false)
    }
    options[option.index].selected = !option.selected;
    setOptions([...options]);
    setBackup([]);
    if (quickSelection) setNewSelectionDesktop();
  }

  const toggleDisplayOptions = useCallback(() => {
    if (optionsAlwaysVisible) return;
    setDisplayOptions(!displayOptions);
  }, [optionsAlwaysVisible, displayOptions])

  const activateDisplayOptions = useCallback(() => {
    if (optionsAlwaysVisible) return;
    setDisplayOptions(true);
  }, [optionsAlwaysVisible])

  const iconStyles = {
    transform: 'rotate(90deg)',
    marginLeft: '10px'
  }

  const inputHandler = (e) => {
    setSearchInput(e.target.value);
    setTempSelection({ value: null, name: e.target.value });
  }

  React.useEffect(() => {
    if (searchInput.length > 0) {
      let sCoincidences = options.filter((option) => {
        return (option.name.toLowerCase().includes(searchInput.toLowerCase()));
      });
      setCoincidences(sCoincidences);
    }
    else {
      setCoincidences([]);
    }
  }, [searchInput, options])

  useEffect(() => {
    if (mustResetFilters) {
      if (quickSelection) clear(true, false);
      else {
        clear(true, false);
        setNewSelectionDesktop(false);
      }
    }
  }, [mustResetFilters, quickSelection, clear, setNewSelectionDesktop])

  return (
    <ModalWrapper
      display={displayOptions}
      setDisplay={setDisplayOptions}
      ignoreDisplaychanges={optionsAlwaysVisible ? true : false}>
      <div className={style.wrapper}>
        {
          options ? (
            <div>
              <div className={style.selectMobile}>
                {/* <span className={style.boxWrapperLabel}>{name}</span> */}
                <select className={`${style.boxWrapper} ${main ? style.main : ''}`} name={name} onChange={(e) => { updateSelectionMobile(e.target.selectedOptions) }} multiple={multiple ? true : false} defaultValue={multiple ? [''] : ''}>
                  <option value="" disabled hidden>{label ?? name}</option>
                  {
                    options.map((option, index) => {
                      return <option key={index} value={option.value}>{option.name}</option>
                    })
                  }
                </select>
                {/* <button onClick={callback ? () => callback(selection) : () => { }}>Apply</button> */}
              </div>
              <div className={style.selectDesktop}>
                <div style={{ position: 'relative' }}>
                  {/* <span className={style.boxWrapperLabel}>{name}</span> */}
                  {
                    searchable ? (
                      <input className={`${style.boxWrapper} ${main ? style.main : ''}`} type="text" onChange={inputHandler} value={tempSelection ? tempSelection.name : selection[0] ? selection[0].name : ''} onFocus={activateDisplayOptions} placeholder={label ?? name} />
                    ) : (
                      <div
                        name={name}
                        className={`${style.boxWrapper} ${main ? style.main : ''} ${canHighlight && selection.length > 0 ? style.highlight : ''}`}
                        onClick={toggleDisplayOptions}
                        style={autoWidth ? { width: 'inherit', ...props.style } : { ...props.style }}>
                        <span>
                          {
                            (main && !multiple) || quickSelection ? (
                              selection[0] ? (
                                `${label ?? name}: ${selection[0].name}`
                              ) : (
                                `${label ?? name}`
                              )
                            ) : multiple && selection.length == 1 ? (
                              `${label ?? name}: ${selection[0].name}`
                            ) : multiple && selection.length > 1 ? (
                              `${label ?? name} (${selection.length}): ${selection.map(option => option.name.split(' ').map(w => w.substring(0,3)).join(' ')).join(', ')}`
                            ) : (
                              `${label ?? name}`
                            )
                          }
                        </span>
                        <FontAwesomeIcon style={iconStyles} icon={faAngleRight} />
                      </div>
                    )
                  }
                  {
                    displayOptions ? (
                      <div className={style.optionsWrapper} style={{ right: `${position === 'right' ? '0' : 'auto'}`, position: `${optionsAlwaysVisible ? 'relative' : 'absolute'}` }}>
                        <div className={style.selectionWrapper}>
                          {
                            !searchable && selection.length > 0 ? (
                              <div className={style.selection}>
                                {
                                  selection.map(option => {
                                    return (
                                      <div key={option.index} className={`${style.option} ${option.selected ? style.selected : ''}`}>
                                        <label htmlFor={name + '_' + option.name}>{option.name}</label>
                                        <input
                                          type='checkbox'
                                          id={name + '_' + option.name}
                                          onChange={(e) => updateSelection(e, option)}
                                          checked={option.selected}
                                          style={{ opacity: (!multiple && !checkboxes) || quickSelection ? '0' : '1' }}>
                                        </input>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )
                          }

                          <div className={style.options}>
                            {
                              searchInput.length > 0 ? (
                                coincidences.length > 0 ? (
                                  coincidences.map(option => {
                                    if (multiple && selection.map(option => option.index).includes(option.index))
                                      return null
                                    else {
                                      return (
                                        <div key={option.index} className={`${style.option} ${option.selected ? style.selected : ''}`}>
                                          <label htmlFor={name + '_' + option.name}>{option.name}</label>
                                          <input
                                            type='checkbox'
                                            id={name + '_' + option.name}
                                            onChange={(e) => {
                                              updateSelection(e, option);
                                            }}
                                            checked={option.selected}
                                            style={{ opacity: (!multiple && !checkboxes) || quickSelection ? '0' : '1' }}>
                                          </input>
                                        </div>
                                      )
                                    }
                                  })
                                ) : (
                                  <div className={style.option}>
                                    <label>No results</label>
                                  </div>
                                )
                              ) : (
                                options.map(option => {
                                  if (multiple && selection.map(option => option.index).includes(option.index))
                                    return <React.Fragment key={option.index}></React.Fragment>
                                  else {
                                    return (
                                      <div key={option.index} className={`${style.option} ${option.selected ? style.selected : ''} ${option.disabled ? style.disabled : ''}`}>
                                        <label htmlFor={name + '_' + option.name}>{option.name}</label>
                                        <input
                                          type='checkbox'
                                          id={name + '_' + option.name}
                                          onChange={(e) => {
                                            updateSelection(e, option);
                                          }}
                                          checked={option.selected}
                                          disabled={option.disabled}
                                          style={{ opacity: (!multiple && !checkboxes) || quickSelection ? '0' : '1' }}>
                                        </input>
                                      </div>
                                    )
                                  }
                                })
                              )
                            }
                          </div>
                        </div>
                        {
                          extraActions === false ? (
                            null
                          ) : (
                            <div className={style.buttonsWrapper}>
                              <div className={style.selectAll} style={{ display: multiple ? '' : 'none' }}>
                                <input
                                  type='checkbox'
                                  id={name + '_select-all'}
                                  onChange={selectAll}
                                  checked={backup.length > 0}>
                                </input>
                                <label htmlFor={name + '_select-all'}>Select all</label>
                              </div>
                              <div className={style.clearApply} style={multiple ? {} : { width: '100%', justifyContent: 'space-around' }}>
                                <button onClick={clear}>Clear</button>
                                {
                                  !quickSelection ? (
                                    <button onClick={setNewSelectionDesktop}>Apply</button>
                                  ) : (
                                    <React.Fragment />
                                  )
                                }
                              </div>
                            </div>
                          )
                        }
                      </div>
                    ) : (
                      <div></div>
                    )
                  }
                </div>
              </div>
            </div>
          ) : (
            <React.Fragment />
          )
        }
      </div >
    </ModalWrapper>
  )
}

export default Select;