import React, { useState, useEffect } from 'react';
import style from './ProgressBar.module.css';
import InputRange from 'react-input-range';
import './Styles.css';

const ProgressBar = (props) => {
  const { name, keyName, max, step, editing, callback, scoreDetails, total, allowFeedback, originalData, weight } = props;
  const [value, setvalue] = useState(0);
  const [deltaValue, setDeltaValue] = useState(undefined);
  useEffect(() => {
    if (total) {
      setvalue(total * 100);
      return;
    }
    let temp = 0;
    let originalTemp = 0;
    if (typeof scoreDetails === 'object') {
      Object.keys(scoreDetails).forEach(function (key, index) {
        temp += scoreDetails[key]
        if (originalData !== undefined && originalData !== null && originalData.result !== undefined) {
          originalTemp += originalData.result.score_details[keyName][key]
        }
      })
      if (allowFeedback && originalData !== undefined && originalData !== null && originalData.result !== undefined) {
        setDeltaValue(Math.round(((originalTemp - temp) + Number.EPSILON) * 100) / 100);
      }
      setvalue(temp * 100);
    }
    else {
      setvalue(scoreDetails * 100);
      if (allowFeedback && originalData !== undefined && originalData !== null && originalData.result !== undefined) {
        setDeltaValue(Math.round(((originalData.result.score_details[keyName] - scoreDetails) + Number.EPSILON) * 100) / 100);
      }
    }
  }, [scoreDetails, total, originalData, allowFeedback, keyName]);

  useEffect(() => {
    if (callback) callback(keyName, value / 100)
  }, [callback, keyName, value])

  const updateValue = (value) => {
    setvalue(value);
    if (callback) callback(keyName, value / 100);
  }

  return (
    <div className={'wrapper'}>
      <div className={style.information}>
        <span className={style.name}>{`${name}${ allowFeedback && weight !== undefined ? ` (${weight.toFixed(2)})` : ''}`}</span>
        <div className={style.scoreWrapper}>
          {
            deltaValue !== undefined ? (
              <span className={style.score} style={{ color: deltaValue < 0 ? '#35CF6C' : deltaValue > 0 ? '#FF5E48' : '#2864C8' }}>
                {`(${deltaValue > 0 ? '-' : '+'} ${(Math.abs(deltaValue)).commaRepresentation(Math.log10(1 / step))})`}
              </span>
            ) : (
              null
            )
          }
          <span className={style.score}>{(value / 100).toFixed(Math.log10(1 / step))}</span>
        </div>
      </div>
      <InputRange
        step={step * 100}
        maxValue={max * 100}
        minValue={0}
        value={value}
        onChange={value => updateValue(value)}
        disabled={!editing}>
      </InputRange>
    </div>
  );
};

export default ProgressBar;