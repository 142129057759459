import axiosInstance from './AxiosAPI';

export async function FetchService({ endpoint, data, method = 'POST', dataType = 'FORM-DATA', alertErrors = true, responseType = undefined, customAlerts = undefined }) {
  endpoint = `api/${endpoint}`;
  let requestData = {};
  if (data) {
    if (method === 'POST' || method === 'PATCH') {
      if (dataType !== 'JSON') {
        requestData = new FormData();
        for (const pair of Object.entries(data)) {
          requestData.append(pair[0], pair[1]);
        }
      }
      else requestData = JSON.stringify(data);
    }
    else if (method === 'GET') {
      requestData = { params: data }
    }
  }
  if (responseType) {
    requestData.responseType = responseType;
  }
  if (method === 'POST' || method === 'PATCH') axiosInstance.defaults.headers['Content-Type'] = dataType !== 'JSON'? 'multipart/form-data' : 'application/json';
  try {
    let response = {}
    if (method === 'POST') response = await axiosInstance.post(endpoint, requestData);
    else if (method === 'GET')response = await axiosInstance.get(endpoint, requestData);
    else if (method === 'DELETE')response = await axiosInstance.delete(endpoint, requestData);
    else if (method === 'PATCH')response = await axiosInstance.patch(endpoint, requestData);
    if (response.headers['content-type'] !== "application/pdf") {
      let errKey = response.data && 'error' in response.data ? 'error' : response.data && 'err' in response.data ? 'err' : undefined;
      if (errKey && (typeof response.data[errKey] !== 'boolean')) {
        /* To handle identified backend errors */
        if (response.data[errKey].includes('Unexpected error while checking permissions')) {
          window.location = `/api/login?next=${window.location.pathname}`;
        }
        else {
          //alert(`There was an internal error`);
          console.log(`Error at /${endpoint}, ${response.data[errKey]}`);
        }
        return null;
      }
    }
    return response;
  } catch (error) {
    /* To handle unidentified backend errors */
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      window.location = `/api/login?next=${window.location.pathname}`;
    }
    if (alertErrors && error.response && error.response.data && (error.response.data.err || error.response.data.error)) {
      window.alert('Error: ' + (error.response.data.err || error.response.data.error));
    }
    else if (alertErrors && error.response && customAlerts && customAlerts.code === error.response.status) {
      window.alert('Error: ' + (customAlerts.alert ?? ''));
    }
    else if (alertErrors && error.response && error.response.status === 504) {
      window.alert("Error: We're sorry, but the request you're making is too heavy and has exceeded the allowed time limit. This may be due to an unusually high load on our servers");
    }
    else if (alertErrors && error.response && error.response.status === 503) {
      window.alert("Error: Currently, our service is not available due to maintenance tasks or high demand");
    }
    else if (alertErrors && error.response && error.response.status === 500) {
      window.alert("Error: An internal server error occurred while processing your request");
    }
    else if (alertErrors && error.response && error.response.status === 502) {
      window.alert("Error: A Bad Gateway error occurred while processing your request");
    }
    throw new Error(`Error at /${endpoint} status code ${error.response && error.response.status}`, {cause: error});
  }
}

